
import { computed, defineComponent, inject, ref } from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import MenuOptionPopup from "@/components/atomics/MenuOptionPopup.vue";
import Notification from "@/components/atomics/Notification.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "AfterLoginPageLayout",
  components: {
    TextButton,
    MenuOptionPopup,
    Notification,
  },
  props: {
    isSubHeaderMenu: { type: Boolean, default: false },
    rightPartPopup: { type: Boolean, default: true },
    nonePaddingTop: { type: Boolean, default: false },
    useLayoutNotification: { type: Boolean, default: true },
  },
  emits: ["onMaxScroll"],
  setup() {
    const isShowSidebar = ref(false);
    const scrollbarWidth = inject("scrollbarWidth");
    const scrollable = ref(null) as any;
    const mainContent = ref(null) as any;
    const isMobile = inject("isMobile") as any;
    const hasScrollbar = ref(false);
    const store = useStore();
    return {
      isShowSidebar,
      scrollbarWidth,
      scrollable,
      mainContent,
      isMobile,
      hasScrollbar,
      showInPopup: computed(() => store.state.notification.showInPopup),
    };
  },
  watch: {
    isMobile() {
      this.registerScrollEvent(this.isMobile);
    },
    scrollbarWidth() {
      this.setScrollerPadding();
    },
    hasScrollbar() {
      this.setScrollerPadding();
    },
  },
  methods: {
    registerScrollEvent(isMobile: any) {
      const scrollDesktop = (this.$el as HTMLElement).querySelector(
        ".left-part > .bottom-part"
      ) as HTMLElement;
      const scrollMobile = document.body;
      if (isMobile) {
        scrollMobile.onscroll = (evt) =>
          this.scrollEvent(document.scrollingElement);
        scrollDesktop.removeEventListener("scroll", (evt) =>
          this.scrollEvent(scrollDesktop)
        );
      } else {
        scrollDesktop.addEventListener("scroll", (evt) =>
          this.scrollEvent(scrollDesktop)
        );
        scrollMobile.removeEventListener("scroll", (evt) =>
          this.scrollEvent(document.scrollingElement)
        );
      }
    },
    scrollEvent(element: any) {
      var maxScroll = element.scrollHeight - element.offsetHeight;
      if (element.scrollTop >= maxScroll && maxScroll != 0) {
        this.$emit("onMaxScroll", element);
      }
    },
    setScrollerPadding() {
      if (!this.$el) return;
      var scrollbarWidth = this.hasScrollbar ? this.scrollbarWidth : 0;
      this.mainContent.style.paddingRight = scrollbarWidth + "px";
    },
    scroller(event: WheelEvent) {
      switch (event.deltaMode) {
        case 0: //DOM_DELTA_PIXEL		Chrome
          this.scrollable.scrollTop += event.deltaY;
          this.scrollable.scrollLeft += event.deltaX;
          break;
        case 1: //DOM_DELTA_LINE		Firefox
          this.scrollable.scrollTop += 15 * event.deltaY;
          this.scrollable.scrollLeft += 15 * event.deltaX;
          break;
        case 2: //DOM_DELTA_PAGE
          this.scrollable.scrollTop += 0.03 * event.deltaY;
          this.scrollable.scrollLeft += 0.03 * event.deltaX;
          break;
      }
      event.stopPropagation();
      event.preventDefault();
    },
    detectScrollbar() {
      this.hasScrollbar =
        this.scrollable?.scrollHeight > this.scrollable?.clientHeight;
    },
  },
  updated() {
    this.$nextTick(this.detectScrollbar);
  },
  mounted() {
    var scroll = (this.$el as HTMLElement).getElementsByClassName("scroll");
    Array.from(scroll).forEach((item) => {
      (item as HTMLElement).onwheel = (event: WheelEvent) => {
        var scrollChild = item as HTMLElement;
        if (event.deltaY < 0 && (scrollChild.scrollTop ?? 0.0) <= 0) {
          this.scroller(event);
        }
        var maxScrollContennt =
          scrollChild.scrollHeight - scrollChild.offsetHeight;
        if (
          event.deltaY > 0 &&
          (scrollChild.scrollTop ?? 0.0) >= maxScrollContennt
        ) {
          this.scroller(event);
        }
        return true;
      };
    });
    // this.detectScrollBar();
    // console.log(this.hasScrollbar);
    this.setScrollerPadding();
    this.registerScrollEvent(this.isMobile);
    window.addEventListener("resize", this.detectScrollbar);

    const scrollDesktop = (this.$el as HTMLElement).querySelector(
      ".left-part > .bottom-part"
    ) as HTMLElement;
    const scrollMobile = document.body;
    this.scrollEvent(this.isMobile ? scrollMobile : scrollDesktop);
  },
  deactivated() {
    window.removeEventListener("resize", this.detectScrollbar);
  },
});
