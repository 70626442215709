import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "after-login-page-layout" }
const _hoisted_2 = { class: "menu-header title" }
const _hoisted_3 = {
  key: 0,
  class: "mobile menu-button"
}
const _hoisted_4 = { class: "menu-header title invisible" }
const _hoisted_5 = {
  class: "main-content",
  ref: "mainContent"
}
const _hoisted_6 = { class: "left-part" }
const _hoisted_7 = {
  key: 0,
  class: "notification-part"
}
const _hoisted_8 = {
  key: 0,
  class: "mobile"
}
const _hoisted_9 = { class: "menu-header" }
const _hoisted_10 = {
  key: 0,
  class: "mobile menu-button"
}
const _hoisted_11 = {
  key: 0,
  class: "pt-3",
  style: {"visibility":"hidden"}
}
const _hoisted_12 = {
  key: 0,
  class: "mobile"
}
const _hoisted_13 = { class: "menu-header" }
const _hoisted_14 = { class: "right-part mt-3 scroll" }
const _hoisted_15 = {
  key: 0,
  class: "top-part"
}
const _hoisted_16 = {
  key: 1,
  class: "bottom-part"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_MenuOptionPopup = _resolveComponent("MenuOptionPopup")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "title"),
      (_ctx.rightPartPopup && !_ctx.isSubHeaderMenu && _ctx.$slots.bottomRight)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_MenuOptionPopup, null, {
              target: _withCtx(({ showMenuOption }) => [
                _createVNode(_component_TextButton, {
                  icon: require('icons/more.svg'),
                  "is-show-text": false,
                  onOnClick: showMenuOption
                }, null, 8, ["icon", "onOnClick"])
              ]),
              options: _withCtx(() => [
                _renderSlot(_ctx.$slots, "bottomRight")
              ]),
              _: 3
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "title")
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_ctx.useLayoutNotification && !_ctx.showInPopup)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_Notification, {
                class: _normalizeClass(["notification-desk", { 'notification-mob': _ctx.isMobile }])
              }, null, 8, ["class"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots.topRight || _ctx.$slots.topLeft)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["top-part scroll", {
            'pt-3': (_ctx.$slots.topRight || _ctx.$slots.topLeft) && !_ctx.nonePaddingTop,
          }])
            }, [
              (_ctx.$slots.topRight)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _renderSlot(_ctx.$slots, "topRight")
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_9, [
                _renderSlot(_ctx.$slots, "topLeft"),
                (_ctx.rightPartPopup && _ctx.isSubHeaderMenu && _ctx.$slots.bottomRight)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_MenuOptionPopup, null, {
                        target: _withCtx(({ showMenuOption }) => [
                          _createVNode(_component_TextButton, {
                            icon: require('icons/more.svg'),
                            "is-show-text": false,
                            onOnClick: showMenuOption
                          }, null, 8, ["icon", "onOnClick"])
                        ]),
                        options: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "bottomRight")
                        ]),
                        _: 3
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass([{ 'pt-3': !(_ctx.$slots.topRight || _ctx.$slots.topLeft) }, "bottom-part scrollable"]),
          ref: "scrollable"
        }, [
          (_ctx.$slots.topRight || _ctx.$slots.topLeft)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (_ctx.$slots.topRight)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _renderSlot(_ctx.$slots, "topRight")
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_13, [
                  _renderSlot(_ctx.$slots, "topLeft")
                ])
              ]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "bottomLeft", { detectScrollbar: _ctx.detectScrollbar })
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_14, [
        (_ctx.$slots.topRight)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _renderSlot(_ctx.$slots, "topRight")
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots.bottomRight)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _renderSlot(_ctx.$slots, "bottomRight")
            ]))
          : _createCommentVNode("", true)
      ])
    ], 512)
  ]))
}